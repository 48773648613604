import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import '@usb-shield/react-grid/dist/library/styles/index.css'
import footerStyles from '@/components/PageFooter/pageFooter.module.scss'
import Link from 'next/link'
import FooterSocialIcons from '@/components/FooterSocialIcons/FooterSocialIcons'
import { footerData } from '@/components/PageFooter/pageFooter.model'
import FooterLink from '@/components/PageFooter/components/FooterLink'

const footerLeftBlock = {
  span: null,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 4,
    small: 2,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const footerRightBlock = {
  span: null,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 4,
    small: 2,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'end',
}

const footerLeftBlockWithDisclaimer = {
  span: null,
  spans: {
    xlarge: 6,
    large: 8,
    medium: 4,
    small: 2,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const footerRightBlockWithDisclaimer = {
  span: null,
  spans: {
    xlarge: 6,
    large: 8,
    medium: 4,
    small: 2,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'end',
}

const disclaimerLayout = {
  span: null,
  spans: {
    xlarge: 16,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const fourteenColsOption = {
  span: null,
  spans: {
    xlarge: 14,
    large: 14,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const twoColsOption = {
  span: null,
  spans: { xlarge: 2, large: 2, medium: 2, small: 4 },
  offset: { xlarge: 0, large: 0, medium: 0, small: 0 },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const PageFooter = ({
  footerData,
  quickLinkData,
}: {
  footerData: footerData
  quickLinkData?: any
}) => {
  const items = footerData?.bottomBarLeftLinkList.map(
    (item: any, index: number, menuList: any) => {
      return (
        <FooterLink index={index} link={item['cta-link']} text={item['cta-text']} openInNewTab={item['openInNewTab']} />
      )
    }
  )
  const footer_rightlinks = footerData?.rightLinkList.map(
    (item: any, index: number, menuList: any) => {
      if ((menuList.length === index + 1) || (menuList.length === index + 2)) {
        return (
          <li className={footerStyles.quicklinksListItem} key={index}>
            {item['cta-link'] !== '' && (<Link href={item['cta-link']}>
              <a className={footerStyles.quickLinkNoPipe}>{item['cta-text']}</a>
            </Link>)}
            {item['cta-link'] === '' && (
              <p className={footerStyles.quickLinkNoPipe + ' ' + footerStyles.quickLinkColor}>{item['cta-text']}</p>)}
          </li>
        )
      }

      return (
        <li className={footerStyles.quicklinksListItem} key={index}>
         {item['cta-link'] !== '' && (<Link href={item['cta-link']}>
            <a className={footerStyles.quickLinkWithPipe}>{item['cta-text']}</a>
          </Link>)}
          {item['cta-link'] === '' && (
              <p className={footerStyles.quickLinkNoPipe + ' ' + footerStyles.quickLinkColor}>{item['cta-text']}</p>)}
        </li>
      )
    }
  )
  
  let customFooterStyles: any = ''
  if (footerData?.enableQuickLinks) {
    customFooterStyles = footerStyles.footerGrid
  } else {
    customFooterStyles = footerStyles.footerGridLeft
  }

  let footerLeftLayout: any = ''
  let footerRightLayout: any = ''
  let footerPadding: any = ''
  if (
    footerData?.disclaimerText !== 'undefined' ||
    footerData?.disclaimerText.trim().length !== 0
  ) {
    footerLeftLayout = footerLeftBlockWithDisclaimer
    footerRightLayout = footerRightBlockWithDisclaimer
    footerPadding = footerStyles.footerZeroPadding
  } else {
    footerLeftLayout = footerLeftBlock
    footerRightLayout = footerRightBlock
  }


  return (
    <>
      <USBGrid
        gridGap="zero"
        addClasses={`${footerStyles.gridWrapper} ${footerStyles.copyright_grid} ${customFooterStyles} ${footerPadding}`}
        alignItems="stretch"
        columnCount="16"
        justifyContent="end"
        display="grid"
        padding="normal"
        addId={footerStyles.footerSection}
      >
        <USBColumn
          layoutOpts={
            process.env.SITE_BRAND !== 'elavon' 
              ? footerLeftLayout
              : fourteenColsOption
            }
          addClasses={footerStyles.quickLinksBlock}
        >
          <ul
            className={footerStyles.quickLinksList}
            data-testid={`quickLinksList`}
          >
            {items}
          </ul>{' '}
        </USBColumn>

        <USBColumn
          layoutOpts={
            process.env.SITE_BRAND !== 'elavon' 
            ? footerRightLayout
            : twoColsOption
          }
          addClasses={footerStyles.copyrightBlock}
        >
          <>
            <ul
              className={footerStyles.quickLinksList}
              data-testid={`rightLinkList`}
            >
              {footer_rightlinks}
            </ul>{' '}
            {quickLinkData?.length && (
              <div className={footerStyles.footerSocialIcons}>
                <FooterSocialIcons
                  quickLinkData={quickLinkData}
                ></FooterSocialIcons>
              </div>
            )}
          </>
        </USBColumn>

        {footerData?.disclaimerText && (
          <>
            <USBColumn layoutOpts={disclaimerLayout}>
              <div
                className={footerStyles.disclaimerText}
                dangerouslySetInnerHTML={{
                  __html: footerData?.disclaimerText,
                }}
              ></div>
            </USBColumn>
          </>
        )}
      </USBGrid>
    </>
  )
}

export default PageFooter
