import Image from 'next/image'
import styles from '@/components/FooterQuickLinks/footerQuickLinks.module.scss';
import '@usb-shield/react-dropdown/dist/library/styles/index.css'

const FooterQuickLinks = ({ quickLinkData }: any) => {
    let footerTitleDispayArray = []
    let footerTitleDataArray = [] as any
    let footerColsArray = []

    for (let [key, footerDeatilsValue] of Object.entries(quickLinkData) as any) {
        for (let key in footerDeatilsValue as any) {
            if (key.includes('footerCol')) {
                footerColsArray.push(footerDeatilsValue[key])
            }

            let data = footerDeatilsValue[key]
            footerTitleDispayArray.push(key)
            footerTitleDataArray[key] = data
        }
    }
    return (
        <>
            {
                quickLinkData[0]?.isEnableSocialSection &&
                (
                    <><div className={styles.socialIcons} data-testid={`socialIcons`}>
                        {footerTitleDataArray['socialIcons'] &&
                            footerTitleDataArray['socialIcons'].map(
                                (socialMedia: any, index: any) => (
                                    <a key={index} href={socialMedia.iconLink}>
                                        <img
                                            src={socialMedia.iconImage}
                                            alt={socialMedia.title}
                                            width={30}
                                            height={30} />
                                    </a>
                                )
                            )}
                    </div></>
                )
            }

        </>
    )
}

export default FooterQuickLinks
